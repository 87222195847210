
<template>
    <div class="nftDetail">
        <div class="router">
            <div class="center connectBoxW">
                <router-link to="/Farm" tag="a" v-if="toRouteName == 'Farm'" href=""><i class="el-icon-arrow-left"></i> Back to FARM</router-link>
                <router-link to="/MyNFT" tag="a" v-if="toRouteName == 'MyNFT'" href=""><i class="el-icon-arrow-left"></i> Back to MY NFT</router-link>
                <router-link to="/Staking" tag="a" v-if="toRouteName == 'Staking'" href=""><i class="el-icon-arrow-left"></i> Back to STAKING</router-link>
                <router-link to="/Marketplace" tag="a" v-if="toRouteName == 'Marketplace'" href=""><i class="el-icon-arrow-left"></i> Back to MARKETPLACE</router-link>
            </div>
        </div>
        <div class="infobox">
            <div class="center connectBoxW">
                <div class="nftimg">
                    <div class="imgbg">
                        <el-image :src="nftInfo.nftUrl" alt="Failed to load" lazy>
                            <div slot="placeholder" class="image-slot">
                                <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .<span class="dot"> </span>
                            </div>
                            <div slot="error" class="image-slot" style="color:#fff;">
                                <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .
                            </div>
                        </el-image>
                    </div>
                </div>
                <div class="nftinfo">
                    <div class="title">
                        <div class="titleLeft">
                            <p v-if="type == 2">{{tokenSymbol}} - STAKING</p>
                            <p v-if="type == 1">{{tokenSymbol}} - {{price}}</p>
                            <p v-if="type == 0">{{tokenSymbol}} - No mining or selling yet</p>
                            <h1><span>{{nftInfo.name}}</span><span>1.10 x</span></h1>
                            <i></i>
                        </div>
                        <div class="titleIcon">
                            <img v-if="nftInfo.level == 0" src="../assets/nftDetail/commonicon.png" alt="">
                            <img v-if="nftInfo.level == 1" src="../assets/nftDetail/uniqueicon.png" alt="">
                            <img v-if="nftInfo.level == 2" src="../assets/nftDetail/exclusiveicon.png" alt="">
                        </div>
                    </div>
                    <div class="info">
                        <p>
                            NO.:
                            <br />
                            <span>{{nftInfo.No}}</span>
                        </p>
                        <p>
                            HOLDER:
                            <br />
                            <span v-if="nftInfo.holder">{{nftInfo.holder.substring(0,19)}}…</span>
                        </p>
                        <p class="MINTPROBABILITY">
                            MINT PROBABILITY:
                            <br />
                            <span>{{nftInfo.probability}}</span>
                        </p>
                        <p class="NFTASSETS">
                            NFT ASSETS:
                            <br />
                            <span>{{nftInfo.assets}} {{tokenSymbol}}</span>
                        </p>
                    </div>
                    <div class="btns">
                        <p v-if="type == 0" :class="!marketLoading ? 'staking' : 'staking no'" @click="staking">{{stakingLoading ? 'Processing' : 'STAKING'}}</p>
                        <p v-if="type == 0 && stakingLoading" class="sell no" >SELL</p>
                        <p v-if="type == 0 && !stakingLoading" class="sell" @click="messageBoxShow=true;messageType = 'sellPlanet'">SELL</p>
                        <p v-if="type != 0" class="buy" @click="buy">BUY NOW  <v-LoadingWarp style="margin-left: 5px;" v-if="buyBtnShow" :imgSize="2"></v-LoadingWarp></p>
                        <p v-if="type == 1" class="price"><img src="../assets/nftDetail/species.png" alt="" srcset=""> {{price}}</p>
                        <p v-if="type == 2" class="tip">In staking, you can not buy or sell.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="detailBottom">
            <div class="center connectBoxW">
                <div class="tabs">
                    <p :class="tabIndex == 0 ? 'active' : ''" @click="tabIndex = 0">INTRODUCTION</p>
                    <p :class="tabIndex == 1 ? 'active' : ''" @click="tabIndex = 1">ON-CHAIN DATA</p>
                    <p :class="tabIndex == 2 ? 'active' : ''" @click="tabIndex = 2">HISTORY</p>
                </div>
            </div>
            <div class="border"></div>
            <div class="center connectBoxW">
                <div v-if="tabIndex == 0" class="item INTRODUCTIONinfo">
                    <h1>Introduction</h1>
                    <p v-html="nftInfo.textDetail"></p>
                </div>
                <div v-if="tabIndex == 1" class="item ONCHAINDATAinfo">
                    <h1>On-chain data</h1>
                    <p>Generation time: {{nftInfo.createTime}} UTC</p>
                    <p>Contract Address: {{nftAddress}}</p>
                    <p>Token ID: #{{NFTId}}</p>
                    <p>Token contracts: ERC721</p>
                    <p>Asset public chain: {{chainName}}</p>
                </div>
                <div v-if="tabIndex == 2" class="item HISTORYinfo">
                    <h1>History</h1>
                    <div class="tableBox">
                        <el-table
                            empty-text="No data"
                            :data="tableData"
                            style="width: 100%">
                            <el-table-column
                                prop="to_time"
                                label="Transaction time">
                            </el-table-column>
                            <el-table-column
                                prop="from_address"
                                label="Sell address">
                                <template slot-scope="scope">
                                    {{changeAddress(scope.row.from_address)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="to_address"
                                label="Buy address">
                                <template slot-scope="scope">
                                    {{changeAddress(scope.row.to_address)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="price"
                                label="Transaction price">
                                <template slot-scope="scope">
                                    {{changePrice(scope.row.price)}} {{tokenSymbol}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="messageBox" v-if="messageBoxShow" @click="closemessage">
            <div class="connectBox connectBoxW">
                <div v-if="messageType == 'sellPlanet'" class="message0" @click.stop>
                    <h1>
                        Sell {{type == 0 ? "Common Planet" : type == 1 ? "Unique Plane" : "Exclusive Star Sign"}}
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="sellnft">
                        <p style="padding-bottom:20px;">Price:</p>
                        <div style="width: 100%; margin-left: 0; margin-bottom: 20px;">
                            <input type="text" v-model="sellPriceVal" placeholder="Place enter the sale price">
                            <p>{{tokenSymbol}}</p>
                        </div>
                    </div>
                    <div class="messagebtn" style="margin-top: 24px;" @click="sellSure">{{marketLoading ? 'Processing' : 'Sell'}}</div>
                </div>
            </div>
        </div>
        <v-Footer></v-Footer>
    </div>
</template>

<script>
import Web3 from 'web3';
import{
    judgeNetwork,getBalanceOf,getApproved,getAllowance,setNFTApprove,setApprove,
    starMeta,cateURI,ownerOf,marketTokenInfo,
    enterStakingNFT,purchaseToken,setTokenSale
} from '../utils/space.js'
export default {
    name: "nftDetail",
    data() {
        return {
            toRouteName:'',
            userAddress:'',tokenSymbol:'',xtokenSymbol:'',chainName:'',SPACEBalanceof:0,
            NFTId:'',type:0,nftInfoList:'',nftProbabilityData:'',
            tokenAddress:'',xTokenAddress:'',nftAddress:'',logicalAddress:'',marketAddress:'',farmAddress:'',farmLibAddress:'',farmPendingAddress:'',
            tabIndex: 0,
            NFTId:0,
            price:0,
            nftInfo:{},
            stakingLoading:false,
            buyBtnShow:false,
            messageBoxShow:false,messageType:'',sellPriceVal:null,marketLoading:false,
            tableData:[
                // {
                //     to_time:'123123',
                //     from_address:'0xsjkdfwefwf',
                //     to_address:'0xsdfsdfsdfsd',
                //     price:'1000.0',
                // }
            ],
        }
    },
    computed:{
        changeAddress() {
            return (val) => {
                var str = val
                return str.substring(0,8)+'XXXXXXXXXX'+str.substring(36)
            }
        },
        changePrice() {
            return (val) => {
                var str = val
                return str.split('.')[0]+'.'+str.split('.')[1].substring(0,6)
            }
        }
    },
    mounted() { 
        this.toRouteName = localStorage.getItem('tonftdetailname')
        //console.log(typeof this.toRouteName)
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.chainName = localStorage.getItem('chainName')
        this.NFTId = this.$route.query.tokenid
        this.type = this.$route.query.type
        this.nftInfoList = window.nftImgData.cardList
        this.nftProbabilityData = window.nftProbabilityData
        this.tokenAddress = window.contractAddr.tokenAddress
        this.xTokenAddress = window.contractAddr.xTokenAddress
        this.nftAddress = window.contractAddr.nftAddress
        this.logicalAddress = window.contractAddr.logicalAddress
        this.marketAddress = window.contractAddr.marketAddress
        this.farmAddress = window.contractAddr.farmAddress
        this.farmLibAddress = window.contractAddr.farmLibAddress
        this.farmPendingAddress = window.contractAddr.farmPendingAddress
        this.getNFTInfo()
        if (this.type == 1) {
            getBalanceOf(this.tokenAddress,this.userAddress).then(res => {
                this.SPACEBalanceof = res/1e18
            })
            this.getNFTMarketInfo()
        }
    },
    methods: {
        staking(){
            if (this.marketLoading) {
                return false
            }
            if (this.stakingLoading) {
                return false
            }
            this.stakingLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.stakingLoading = false
                    return false
                }
                getApproved(this.nftAddress,this.NFTId,this.userAddress).then(approvedRes => {
                    if (approvedRes == 0 || approvedRes.toUpperCase() != this.farmAddress.toUpperCase()) {
                        setNFTApprove(this.nftAddress,this.farmAddress,this.NFTId,this.userAddress).then(res => {
                            this.enterStakingNFT()
                        }).catch(error => {
                            this.stakingLoading = false
                        })
                    } else {
                        this.enterStakingNFT()
                    }
                })
            })
        },
        enterStakingNFT(){
            //console.log(this.farmAddress,this.NFTId,this.userAddress)
            enterStakingNFT(this.farmAddress,this.NFTId,this.userAddress).then(res => {
                this.$router.push({
                    path:'/nftDetail',
                    query:{
                        tokenid:this.NFTId,
                        type:2
                    }
                })
                window.location.reload()
                this.stakingLoading = false
            }).catch(error => {
                this.stakingLoading = false
            })
        },
        sellSure(){
            if (this.marketLoading) {
                return false
            }
            this.marketLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.marketLoading = false
                    return false
                }
                getApproved(this.nftAddress,this.NFTId,this.userAddress).then(approvedRes => {
                    if (approvedRes == 0 || approvedRes.toUpperCase() != this.marketAddress.toUpperCase()) {
                        setNFTApprove(this.nftAddress,this.marketAddress,this.NFTId,this.userAddress).then(res => {
                            this.setTokenSale(true)
                        }).catch(error => {
                            this.sellPriceVal = null
                            this.marketLoading = false
                        })
                    } else {
                        this.setTokenSale(true)
                    }
                })
            })
        },
        setTokenSale(type){
            setTokenSale(this.marketAddress,this.NFTId,type,this.sellPriceVal,this.userAddress).then(res => {
                //console.log(1234)
                this.$router.push({
                    path:'/nftDetail',
                    query:{
                        tokenid:this.NFTId,
                        type:1
                    }
                })
                window.location.reload()
                this.marketLoading = false
                this.messageBoxShow = false
                this.messageType = ''
            }).catch(error => {
                this.sellPriceVal = null
                this.marketLoading = false
                this.messageBoxShow = false
                this.messageType = ''
            })
        },
        buy(){
            if (Number(this.price) > Number(this.SPACEBalanceof)) {
                this.$message({
                    message:'Insufficient Balance',
                    type:'warning'
                })
            } else {
                if (this.buyBtnShow) {
                    return false
                }
                this.buyBtnShow = true
                judgeNetwork().then(res => {
                    if (res == 0) {
                        this.buyBtnShow = false
                        return false
                    }
                    getAllowance(this.tokenAddress,this.marketAddress,this.userAddress).then(allowanceNum => {
                        var allowanceNum = allowanceNum/1e18
                        if (Number(allowanceNum) < Number(this.price)) {
                            setApprove(this.tokenAddress,this.marketAddress,this.userAddress).then(res => {
                                this.$message({ type: 'success', message: 'Approve Success' });
                                this.purchaseToken()
                            }).catch(error => {
                                this.buyBtnShow = false
                            })
                        } else {
                            this.purchaseToken()
                        }
                        
                    })
                })
            }
        },
        purchaseToken(){
            purchaseToken(this.marketAddress,this.NFTId,this.userAddress).then(res => {
                this.buyBtnShow = false
                this.$router.push({name:'MyNFT'})
            }).catch(error => {
                this.buyBtnShow = false
            })
        },

        closemessage(){
            this.messageBoxShow = false
            this.messageType = ''
        },
        getNFTInfo(){
            var Str = Web3.utils.sha3(this.NFTId)
            var NOStr = Str.substring(Str.length-12)
            starMeta(this.logicalAddress,this.NFTId).then(nftInfo => {
                //console.log(nftInfo)
                ownerOf(this.nftAddress,this.NFTId,this.userAddress).then(holder => {
                    cateURI(this.nftAddress,this.NFTId).then(nftUrl => {
                        this.nftInfo = {
                            holder,
                            probability:this.nftProbabilityData[nftInfo.cateId-1].probability,
                            No:NOStr,
                            nftUrl,
                            textDetail:'',
                            cateId:nftInfo.cateId,
                            name:'',
                            level:nftInfo.level,
                            createTime:this.timetrans(nftInfo.createTime*1000-28800000),
                            multiple:Number(nftInfo.multiple/100).toFixed(2),
                            assets:Number((nftInfo.price/Math.pow(10,18))*(nftInfo.multiple/100)).toFixed(0),
                            price:0,
                        }
                        this.nftInfoList.forEach((item,index) => {
                            if (item.cid == nftUrl.split('/')[3]) {
                                this.nftInfo.name = item.name
                                this.nftInfo.textDetail = item.text
                            }
                        })
                        //console.log(this.nftInfo)
                    })
                })
            })
        },
        getNFTMarketInfo(){
            marketTokenInfo(this.marketAddress,this.NFTId,this.userAddress).then(price => {
                this.price = Number((price.price/Math.pow(10,18)))
            })
        },
        timetrans(Time){  
            var _date = new Date(parseFloat(Time))
            var Y = _date.getFullYear() + '.';
            var M = (_date.getMonth()+1 < 10 ? '0'+(_date.getMonth()+1) : _date.getMonth()+1) + '.';
            var D = _date.getDate() + '';
            var h = _date.getHours() + ':';
            var m = _date.getMinutes() + ':';
            var s = _date.getSeconds();  
            if (_date.getDate() < 10 ){D = "0" + D ;}
            if (_date.getHours() < 10 ){h = "0" + h ;}
            if (_date.getMinutes() < 10 ){m = "0" + m ;}
            if (_date.getSeconds() < 10 ){s = "0" + s ;} 
            return(Y+M+D+" "+h+m+s)
            // return(Y+M+D)
        }
    }
}
</script>